import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./Header";
import { MdMarkEmailRead } from "react-icons/md";
import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers/react";
import swal from "sweetalert";
import { Card, Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./Footer";
import CountdownTimer from "./CountdownTimer";

const Home = () => {
  const [email, setEmail] = useState("");
  const { open } = useWeb3Modal();
  const { address, isConnected } = useWeb3ModalAccount();
  const [currentPhase, setCurrentPhase] = useState("Phase 1");
  const subscribeUsWallet = async () => {
    if (isConnected) {
      try {
        const data = await axios.post(
          "https://api.ecotrader.io/api/create-account/",
          { account_id: address }
        );
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    if (isConnected) {
      subscribeUsWallet();
    }
  }, [isConnected]);
  const subscribeUs = async (e) => {
    e.preventDefault();
    if (isConnected) {
      try {
        const data = await axios.post(
          "https://api.ecotrader.io/api/create-account/",
          { email: email, account_id: address }
        );
        if (data) {
          swal({
            icon: "success",
            title: "Subscribed successfully",
          });
        }
        setEmail("");
      } catch (error) {
        if (error.response.status === 400) {
          swal({
            icon: "warning",
            title: "You have already joined the waiting list",
          });
        } else {
          console.log(error);
        }
        setEmail("");
      }
    } else {
      open();
    }
  };

  const items = [
    {
      id: 1,
      imageSrc: "assets/images/comingsoon/resized/solarfields.jpg",
      title: "Solar Fields",
      phase: "Phase 1",
    },

    {
      id: 2,
      imageSrc: "assets/images/comingsoon/resized/greenh2.jpg",
      title: "Green Hydrogen",
      phase: "Phase 1",
    },
    {
      id: 3,
      imageSrc: "assets/images/comingsoon/resized/enrgystorage.jpg",
      title: "Energy Storage",
      phase: "Phase 1",
    },
    {
      id: 4,
      imageSrc: "assets/images/comingsoon/resized/windturbines.jpg",
      title: "Wind Turbines",
      phase: "Phase 2",
    },
    {
      id: 5,
      imageSrc: "assets/images/comingsoon/images/wastetoenergy.jpg",
      title: "Waste to Energy",
      phase: "Phase 2",
    },
    {
      id: 6,
      imageSrc: "assets/images/comingsoon/resized/biogas.jpg",
      title: "Bio-Gas Turbine",
      phase: "Phase 2",
    },
  ];

  const groupedItems = [
    items.filter((item) => item.phase === "Phase 1"),
    items.filter((item) => item.phase === "Phase 2"),
  ];
  const itemCountry = [
    {
      id: 1,
      power: "25MW",
      title: "Solar Field",
      country: "Greece",
      img: "assets/images/comingsoon/countrywise/greece-solar.jpg",
    },

    {
      id: 2,
      power: "50MW",
      title: "Solar Field",
      country: "Crete",
      img: "assets/images/comingsoon/countrywise/crete-solar.jpg",
    },
    {
      id: 3,
      power: "15MW",
      title: "Solar Field",
      country: "Macedonia",
      img: "assets/images/comingsoon/countrywise/macedonia.jpg",
    },
    {
      id: 4,

      power: "15MW",
      title: "Solar Field",
      country: "Bulgaria",
      img: "assets/images/comingsoon/countrywise/bulgaria.jpg",
    },
    {
      id: 5,
      power: "50MW",
      title: "Energy Storage",
      country: "Romania",
      img: "assets/images/comingsoon/countrywise/romania-energy.jpg",
    },
    {
      id: 6,
      power: "132MW",
      title: "Wind Turbines",
      country: "Romania",
      img: "assets/images/comingsoon/countrywise/romania-wind.jpg",
    },
    {
      id: 7,
      power: "150MW",
      title: "Solar Field",
      country: "Romania",
      img: "assets/images/comingsoon/countrywise/romania-solar.jpg",
    },
  ];

  return (
    <>
      <Header />
      <main className="hero-section">
        <div className="container-fluid px-100">
          <div className="row comingsoon">
            <div className="col-12 col-md-12 col-lg-5 col-xl-5 text-center">
              <div className="countdown-timer">
                <CountdownTimer />
              </div>
              <h1
                className="wow fadeInLeft"
                data-wow-delay-duration=".5s"
                data-wow-duration=".5s"
              >
                <span className="private_sale">Private Sale</span> start Soon!
              </h1>
              <h3
                className="wow fadeInLeft"
                data-wow-delay-duration=".5s"
                data-wow-duration=".5s"
              >
                Join the wait list...
              </h3>

              <div className="ad-search">
                <form onSubmit={subscribeUs}>
                  <input
                    type="email"
                    required
                    value={email}
                    className="form-control"
                    placeholder="Your email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button type="submit">
                    <MdMarkEmailRead
                      style={{ height: "35px", width: "35px" }}
                    />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>

      <section className="exp-pro">
        <div className="container-fluid">
          <div className="exp-pro-inner">
            <div
              className="explore wow fadeInLeft"
              data-wow-delay-duration="1s"
              data-wow-duration="1s"
            >
              <div className="exp-circle">
                <span className="circle" />
                <img alt="" src="assets/images/explore-2.svg" />
                <p>{currentPhase}</p>
              </div>
            </div>

            <div className="exp-slider">
              <Carousel
                className="carousel slide mt-0 wow fadeInUp mb-4"
                id="property-slider"
                onSelect={(selectedIndex) => {
                  const currentGroup = groupedItems[selectedIndex];
                  setCurrentPhase(currentGroup[0].phase);
                }}
                interval={2000}
                controls={true}
                indicators={false}
              >
                {groupedItems.map((group, groupIndex) => (
                  <Carousel.Item key={groupIndex} className="text-center">
                    <div className="row">
                      {group.map((item, itemIndex) => (
                        <div key={itemIndex} className="col-12 col-sm-4">
                          <div className="small-card">
                            <div className="card-img">
                              <img
                                alt="Property Image"
                                className="img-fluid mx-auto d-block"
                                src={item.imageSrc}
                                style={{
                                  maxHeight: "400px",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                            <div className="card-content">
                              <h5>{item.title}</h5>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
      <div className="advice_main mb-3">
        <h3 className="text-center pt-4 card_heading">Our Values</h3>
        <div className="row justify-content-center">
          <div
            className="col-xl-3 col-lg-4 col-md-6 col-12 wow fadeInUp mb-4"
            data-wow-duration=".65s"
            data-wow-delay-duration=".65s"
          >
            <div className="advice__card px-4">
              <img
                src="./assets/images/sustainability.avif"
                className="advice__card__image"
                alt="Advice Tool"
              />
              <h2>Sustainability</h2>
              <p>
                We strive to create a lasting, positive environmental impact
                through the promotion, development, and integration of renewable
                energy sources.
              </p>
            </div>
          </div>
          <div
            className="col-xl-3 col-lg-4 col-md-6 col-12 wow fadeInUp mb-4"
            data-wow-duration="1s"
            data-wow-delay-duration="1s"
          >
            <div className="advice__card px-4">
              <img
                src="./assets/images/innovation.avif"
                className="advice__card__image"
                alt="Advice Tool"
              />
              <h2>Innovation</h2>
              <p>
                We constantly explore and examine new technology that can
                improve our energy projects, driving efficiency, and improving
                cost-effectiveness.
              </p>
            </div>
          </div>
          <div
            className="col-xl-3 col-lg-4 col-md-6 col-12 wow fadeInUp mb-4"
            data-wow-duration="1.2s"
            data-wow-delay-duration="1.2s"
          >
            <div className="advice__card px-4">
              <img
                src="./assets/images/collaboration.avif"
                className="advice__card__image"
                alt="Advice Tool"
              />
              <h2>Collaboration</h2>
              <p>
                We work closely with stakeholders, partners, and communities to
                ensure the successful execution of our renewable energy
                projects.
              </p>
            </div>
          </div>
          <div
            className="col-xl-3 col-lg-4 col-md-6 col-12 wow fadeInUp mb-4"
            data-wow-duration="1.4s"
            data-wow-delay-duration="1.4s"
          >
            <div className="advice__card px-4">
              <img
                src="./assets/images/integrity.avif"
                className="advice__card__image"
                alt="Advice Tool"
              />
              <h2>Integrity</h2>
              <p>
                We uphold the highest ethical standards in our business
                practices, remaining transparent and accountable to our clients
                and partners.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="advice px-100">
        <div className="container-fluid" />
        <h3
          className="wow fadeInLeft text-center card_heading"
          data-wow-duration=".5s"
          data-wow-delay-duration=".5s"
        >
          What We Offer
        </h3>
        <div className="advice_main mx-2 my-2">
          <div className="row justify-content-center">
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 wow fadeInUp mb-4"
              data-wow-duration=".65s"
              data-wow-delay-duration=".65s"
            >
              <div className="advice__card px-4">
                <h2>1.</h2>
                <h2>
                  Project <br /> Development
                </h2>
                <p>
                  Our team of experts works closely with stakeholders to
                  identify and assess potential renewable energy projects,
                  providing comprehensive feasibility studies and due diligence
                  services to ensure successful outcomes.
                </p>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 wow fadeInUp mb-4"
              data-wow-duration="1s"
              data-wow-delay-duration="1s"
            >
              <div className="advice__card px-4">
                <h2>2.</h2>
                <h2>Engineering, Construction</h2>
                <p>
                  Our end-to-end EPC solutions ensure that each project is
                  designed, procured, and constructed to the highest standards
                  of quality and efficiency, while adhering to environmental
                  regulations and guidelines.
                </p>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 wow fadeInUp mb-4"
              data-wow-duration="1.2s"
              data-wow-delay-duration="1.2s"
            >
              <div className="advice__card px-4">
                <h2>3.</h2>
                <h2>Operations, Maintenance</h2>
                <p>
                  We offer a complete and comprehensive solution to our partners
                  and investors, managing all aspects of development &
                  construction and maintaining the project once it's up and
                  running.
                </p>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-4 col-md-6 col-12 wow fadeInUp mb-4"
              data-wow-duration="1.4s"
              data-wow-delay-duration="1.4s"
            >
              <div className="advice__card px-4">
                <h2>4.</h2>
                <h2>
                  Power sale & <br /> PPA’s
                </h2>
                <p>
                  Our trade center offers an array of options for short, medium,
                  and long-term power purchases, our team works to adjust the
                  allocation of quotas considering updated market status.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="build-g px-2 py-6">
        <h3 className="text-center pt-4 card_heading pb-4">
          Our Geo Locations
        </h3>
        <div className="container-fluid px-100 container_map_items">
          <div
            className="right wow fadeInUp map_pic"
            data-wow-duration=".7s"
            data-wow-delay-duration=".7s"
          >
            <div className="card-img">
              <img
                src="assets/images/comingsoon/map.png"
                className="map_img"
                alt=""
              />
            </div>
          </div>
          <div className="fw-bold fs-5 mx-2">
            <div className="row row-cols-1 row-cols-md-2 g-4 text-center itemcountry">
              {itemCountry.map((item, index) => (
                <div key={index} className="col py-2">
                  <div className="shadow border rounded p-3">
                    <img
                      src={item.img}
                      alt=""
                      className="img-fluid  d-block rounded"
                    />
                    <h5 className="card-title">{item.country}</h5>
                    <h6 className="card-subtitle mb-2 text-muted">
                      {item.power}
                    </h6>
                    <p className="card-text text-success">{item.title}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;
