import React from "react";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";

const CountdownTimer = () => {
  const targetDate = new Date(2024, 10, 30, 0, 0, 0);

  return (
    <FlipClockCountdown
      labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
      labelStyle={{ fontSize: 10, fontWeight: 500, textTransform: "uppercase" }}
      to={targetDate.getTime()}
      digitBlockStyle={{ width: 40, height: 60, fontSize: 30 }}
      className="countdown"
    />
  );
};

export default CountdownTimer;
